export default function activeSelectedValues() {
  const getActiveSelectedValues = (options, selectedValues) => {
    const tempIds = options.map(value => value.id)
    return selectedValues.filter(value => tempIds.indexOf(value) >= 0)
  }

  return {
    getActiveSelectedValues,
  }
}
