<template>
  <!-- BODY -->
  <div v-if="Object.keys(businessData).length !== 0">
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <form-wizard
        color="#00A5AF"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3 p-3"
        @on-complete="handleSubmit(updateBusiness)"
      >
        <tab-content
          :before-change="()=>{
            if($refs.commercialDetails.$data.flags.invalid)
              $refs.commercialDetails.handleSubmit();
            return !$refs.commercialDetails.$data.flags.invalid
          }"
          title="Commercial Details"
        >
          <validation-observer
            ref="commercialDetails"
            tag="form"
          >
            <commercial-details
              :commercial-details="businessData"
              :classifications="activeClassifications"
              :sub-classifications="subClass"
              :languages="activeLanguages"
            />
          </validation-observer>
          <div class="btn-back">
            <back />
          </div>
        </tab-content>
        <tab-content
          :before-change="()=>{
            if($refs.commercialSocial.$data.flags.invalid)
              $refs.commercialSocial.handleSubmit();
            return !$refs.commercialSocial.$data.flags.invalid
          }"
          title="Social"
        >
          <validation-observer
            ref="commercialSocial"
            tag="form"
          >
            <commercial-social
              :commercial-details="businessData"
              :tag-options="activeTags.filter(tag=>tag.category === 'business')"
              :donation-categories="activeDonationCategories"
            />
          </validation-observer>
        </tab-content>
        <upload-progress :progress="progress" />
      </form-wizard>
    </validation-observer>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'

import commercialDetails from '@/common/components/Business/Edit/CommercialDetails.vue'
import commercialSocial from '@/common/components/Business/Edit/CommercialSocial.vue'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'

import getBusiness from '@/common/compositions/Business/getBusiness'
import getOrganizationDetails from '@/common/compositions/GeneralSettings/getGeneralSettings'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import uploadPercentage from '@/common/compositions/common/uploadPercentage'
import Back from '@/common/components/common/FormInputs/Back.vue'

export default {
  name: 'EditBusiness',
  components: {
    FormWizard,
    TabContent,
    commercialDetails,
    commercialSocial,
    UploadProgress,
    Back,
  },
  data() {
    return {
      autocomplete: null,
      place: null,
      newBusinessData: {},
      classID: null,
    }
  },
  watch: {
    classID() {
      this.getSubClassByClassId()
    },
  },
  created() {
    this.setBusinessData()
  },
  setup() {
    const { progress, calculateUploadPercentage } = uploadPercentage()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {

      successfulOperationAlert,
    } = handleAlerts()

    const { businessData, getBusinessData } = getBusiness()

    const {
      activeLanguages,
      activeTags,
      activeDonationCategories,
      activeClassifications,
    } = getOrganizationDetails()

    const subClass = computed(() => store.getters['generalSettings/getActiveSubClassifications'])

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      activeDonationCategories,
      activeLanguages,
      activeTags,
      activeClassifications,
      subClass,
      businessData,
      getBusinessData,

      successfulOperationAlert,
      progress,
      calculateUploadPercentage,
    }
  },
  methods: {
    updateBusiness() {
      this.setNewBusinessData()
      this.$entities.post(`internalops/commercial_entity/${this.$router.currentRoute.params.id}?_method=PUT`, this.newBusinessData, {
        onUploadProgress: progressEvent => { this.calculateUploadPercentage(progressEvent) },
      }).then(() => {
        this.successfulOperationAlert('Updated Successfully')
        if (this.$can('show', 'global-EntitiesController')) {
          this.$router.push({ name: 'business-list' })
        } else {
          this.$router.back()
        }
      }).catch(err => {
        const text = err.response.data.errors.name?.[0]
        if (text.includes('Entity with same')) {
          const numberRegex = /\d+/

          const match = text.match(numberRegex)
          const extractedNumber = match ? parseInt(match[0], 10) : null
          this.$router.push({ name: 'admin-edit-business', params: { id: extractedNumber } }).then(() => {
            this.successfulOperationAlert(`You are redirected to entity ${this.businessData.name}`)
          })
        }
      })
    },
    setBusinessData() {
      this.getBusinessData().then(() => {
        this.classID = this.activeClassifications.find(activeClass => activeClass.name === 'Business').id
        this.getSubClassByClassId()
      })
    },
    getSubClassByClassId() {
      this.$store.dispatch('generalSettings/getClassSubClassificationRequest', this.classID)
    },
    setNewBusinessData() {
      this.newBusinessData = {
        id: this.$router.currentRoute.params.id,
        name: this.businessData.name,
        parentID: this.businessData.parent?.id,
        lat: this.businessData.location.coordinates[1],
        lng: this.businessData.location.coordinates[0],
        logo: this.businessData.logo[0],
        cover: this.businessData.cover[0],
        about: this.businessData.about,
        contact_name: this.businessData.contact_name,
        phone_number: this.businessData.phone_number,
        email: this.businessData.email,
        social_facebook: this.businessData.social_facebook,
        social_twitter: this.businessData.social_twitter,
        social_instagram: this.businessData.social_instagram,
        social_youtube: this.businessData.social_youtube,
        website: this.businessData.website,
        street: this.businessData.address[0].street,
        city: this.businessData.address[0].city,
        state: this.businessData.address[0].state,
        country: this.businessData.address[0].country,
        postal_code: this.businessData.address[0].postal_code,
        media_files: this.businessData.media_files,
        classifications: this.businessData.classification_id,
        subclassifications: this.businessData.subClassifications_id,
        language_id: this.businessData.languages_id,
        tag_id: this.businessData.tags_id,
        donation_cats_id: this.businessData.donation_categories_id,
        fax_number: this.businessData.fax_number,
        facilitiesIDs: this.businessData.facilities?.map(facility => facility.id),
        servicesIDs: this.businessData.services?.map(service => service.id),
        programsIDs: this.businessData.programs?.map(program => program.id),
        productsIDs: this.businessData.products?.map(product => product.id),
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
